<template>
  <base-section id="pricing-plan" class="text-center">
    <base-section-heading title="Select Plan">
      Compare the features between different product plans and choose the one
      according to your data security needs.
    </base-section-heading>

    <!-- <v-btn-toggle v-model="interval" background-color="white" tile>
      <v-btn active-class="primary white--text" width="125"> Personal </v-btn
      ><v-spacer />
      <v-btn active-class="primary white--text" width="125"> Business </v-btn
      ><v-spacer />
      <v-btn active-class="primary white--text" width="125"> DataVault </v-btn>
    </v-btn-toggle> -->

    <!-- <div class="pa-8" /> -->

    <v-container>
      <v-row>
        <v-col v-for="(plan, i) in plans" :key="i" cols="12" md="4">
          <price-card v-bind="plan" @SubscriptionEvent="SubscriptionEvent" />
        </v-col>
      </v-row>

      <v-dialog
        id="dialogSignup"
        v-model="dialog"
        :max-width="dialogWidth"
        persistent
      >
        <v-card tile outlined elevation="3" :loading="loading">
          <v-system-bar dark color="#000000">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <v-container>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-title>
              <span class="textAlign"
                >Create a FirstVault account to continue</span
              >
            </v-card-title>
            <div v-if="!viewForm">
              <v-row>
                <v-col cols="12">
                  <div class="text-xs-center">
                    <v-btn
                      block
                      rounded
                      color="error"
                      dark
                      @click="Authenticate(googleAuthUrl)"
                    >
                      <v-icon left> mdi-google </v-icon>
                      Sign Up with Google
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <div class="centerAlign">
                    <v-btn
                      block
                      rounded
                      color="primary"
                      dark
                      @click="Authenticate(facebookAuthUrl)"
                    >
                      <v-icon left> mdi-facebook </v-icon>
                      Sign Up with Facebook
                    </v-btn>
                  </div>
                </v-col>
              </v-row> -->
              <v-row class="textAlign">
                <v-col cols="12">
                  <span> By signing up with FirstVault, you agree to our</span
                  ><br /><span>
                    <router-link to="/termsofuse"
                      >Terms of Service
                    </router-link>
                    and
                    <router-link to="/privacypolicy"
                      >Privacy Policy
                    </router-link>
                  </span>
                </v-col>

                <!-- <v-col cols="6">
                    <v-select
                      :items="SubscriptionTypes"
                      label="Subscription Type"
                    ></v-select>
                  </v-col> -->
              </v-row>
              <v-divider class="my-8" />
              <v-row class="textAlign">
                <v-col cols="12">
                  Already have a FirstVault account?
                  <router-link to="/login">Sign-in </router-link>
                </v-col>
              </v-row>
              <v-row class="textAlign">
                <v-col cols="12">
                  Have any questions about FirstVault?
                  <router-link to="/blogs">Learn More </router-link>
                </v-col>
              </v-row>
            </div>
            <v-form ref="formNew" v-model="validNew" v-show="viewForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field
                        v-model="user.first_name"
                        label="First Name"
                        :rules="nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field
                        v-model="user.last_name"
                        label="Last Name"
                        :rules="nameRules"
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="user.mobile_number"
                        label="Mobile Number"
                        :rules="numberRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-if="!StateEnterprise"
                        disabled
                        solo-inverted
                        v-model="user.email_id"
                        label="Email Id"
                        :rules="emailRules"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-if="StateEnterprise"
                        v-model="user.email_id"
                        label="Email Id"
                        :rules="emailRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="4" sm="4" md="4">
                      <v-checkbox
                        v-model="user.enable_mfa"
                        :value="user.enable_mfa"
                        :disabled="mfaDisabled"
                        label="Enable MFA"
                        color="indigo darken-3"
                        hide-details
                        @click="ShowMFADialog"
                      >
                      </v-checkbox>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <custom-password
                        :passwordRule="passwordRule"
                        @updatePassword="UpdatePassword($event)"
                      ></custom-password>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        type="password"
                        v-model="user.re_password"
                        label="Re-enter Password"
                        :rules="[validatePasswordRule]"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="success"
                  text
                  @click="dialogTerms = true"
                  :disabled="!validNew"
                >
                  Sign Up
                </v-btn>
              </v-card-actions>
              <!-- <qrcode
                v-if="user.enable_mfa"
                :qrCodeText="mfa_uri"
                :showQrDialog="showMfa"
                @qrCodeSubmitted="onQrCodeSubmit"
                @closeQrDialog="onCloseQrDialog"
              ></qrcode> -->
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAlert" :max-width="dialogWidth">
        <v-alert :type="alertType">
          <v-row align="center">
            <v-col class="grow">{{ alert_message }}</v-col>
            <v-col class="shrink">
              <v-btn @click="closeAlert">Exit</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-dialog>
      <v-dialog v-model="dialogTerms" max-width="700" persistent>
        <v-card tile>
          <v-card-subtitle class="black white--text">
            <!-- <v-card-subtitle class="black white--text" align="center"> -->
            <h4>
              <span>
                By signing up, you agree to our
                <a @click="dialogViewTerms = true"> Terms of Service </a>
                and
                <a @click="dialogViewPolicy = true"> Privacy Policy </a>
              </span>
            </h4>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn text @click="RegisterNewUser">
              <strong>Accept</strong>
            </v-btn>
            <v-btn text @click="dialogTerms = false">
              <strong>Reject</strong>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogViewTerms"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card tile outlined elevation="3">
          <v-system-bar height="40" dark color="#000000" app>
            <v-spacer></v-spacer>
            <v-btn dark large icon @click="dialogViewTerms = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <terms></terms>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogViewPolicy"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card tile outlined elevation="3">
          <v-system-bar height="40" dark color="#000000" app>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="dialogViewPolicy = false">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <policy></policy>
        </v-card>
      </v-dialog>
    </v-container>
  </base-section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
// import QrCode from "@/components/QrCode.vue";
import cryptoHelper from "@/services/webcrypto.js";
import Terms from "@/components/Terms.vue";
import Policy from "@/components/PrivacyPolicy.vue";
import CustomPassword from "@/components/Custom/CustomPassword.vue";
import PriceCard from "@/components/PriceCard";
export default {
  name: "SectionPricingPlan",

  components: {
    PriceCard,
    // qrcode: QrCode,
    terms: Terms,
    policy: Policy,
    "custom-password": CustomPassword,
  },
  props: {
    SubscriptionType: { type: String, default: "Basic" },
  },
  provide: {
    heading: { align: "center" },
  },
  data: () => ({
    internalPlans: [
      {
        title: "Basic",
        subtitle:
          "A lifetime free digital vault which helps you store passwords and other personal information.",
        monthly: "FREE",
        yearly: "FREE",
        description: [
          "Secure credentials storage - Upto 50",
          "Secure banking info storage",
          "Secure Password Generator",
          "Multi-factor Authentication",
          "6-month complimentary Premium trial",
        ],
      },
      {
        title: "Premium",
        subtitle:
          "All FirstVault Basic features and more features such as unlimited passwords and multiple devices.",
        monthly: 45,
        yearly: 500,
        value: true,
        description: [
          "Secure credentials storage - Unlimited",
          "Dark Web Monitoring",
          "Customized Secure Notes",
          "Category Based password storage",
          "Secure File Storage - upto 1GB",
        ],
      },
      {
        title: "Platinum",
        subtitle:
          "All FirstVault Premium features and more features such as password and file sharing.",
        monthly: 90,
        yearly: 1000,
        description: [
          "Credential history details",
          "Create Family Account",
          "Secure password sharing",
          "Secure file sharing",
          "Secure File Storage - upto 10GB",
        ],
      },
    ],
    interval: 0,
    dialog: false,
    valid: true,
    validNew: true,
    loading: false,
    //SubscriptionTypes: ["Basic", "Premium", "Platinum"],
    subscriptionType: "",
    dialogWidth: "600px",
    alertType: "",
    dialogAlert: false,
    dialogViewPolicy: false,
    dialogViewTerms: false,
    dialogTerms: false,
    alert_message: "",
    cardTitleRegistration: "Sign Up! It's quick and easy.",
    mfa_uri: "No Uri Retreived",
    showMfa: false,
    showRegistrationForm: false,
    showDialogVerifyCode: false,
    radioGroupValue: 2,
    passwordRule: [
      (v) => !!v || "Required",
      (v) =>
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{15,50}$/.test(
          v
        ) || "Password criteria does not match",
    ],
    nameRules: [
      (v) => !!v || "Required",
      (v) => (v && v.length <= 50) || "Must be less than 50 characters",
    ],
    numberRules: [
      (v) => !!v || "Required",
      (v) => /^[0-9]+$/.test(v) || "Only numbers are allowed",
      (v) => (v && v.length == 10) || "Must be 10 digit mobile number",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  mixins: [cryptoHelper],
  created() {
    //console.log("created");
    var params = new URLSearchParams(window.location.search);
    var code = params.get("code");
    var state = params.get("state");
    if (code !== null) {
      this.GetAuthenticationData(code, state);
    }
  },
  computed: {
    ...mapGetters([
      "StateUser",
      "StateAuthenticated",
      "StateAuthenticationMessage",
      "StateEnterprise",
    ]),
    viewForm() {
      if (this.showRegistrationForm || this.StateEnterprise) return true;
      else return false;
    },
    plans() {
      return this.internalPlans.map((plan) => {
        return {
          ...plan,
          features: this.features,
          interval: "Year",
          title: plan.title,
          subtitle: plan.subtitle,
          price: plan.yearly,
          description: plan.description,
        };
      });
    },
    mfaDisabled: function () {
      if (!this.user.email_id) return true;
      return false;
    },
    user: function () {
      return this.StateUser;
    },
    facebookAuthUrl: function () {
      return process.env.VUE_APP_API_AUTH_URL + "Auth/ReAuthorizeUrl/facebook";
    },
    googleAuthUrl: function () {
      return process.env.VUE_APP_API_AUTH_URL + "Auth/ReAuthorizeUrl/google";
    },
    authCallbackUrl: function () {
      //console.log(process.env.VUE_APP_API_AUTH_URL);
      return process.env.VUE_APP_API_AUTH_URL + "Access/Callback";
    },
  },

  methods: {
    ...mapActions(["LogIn", "MutatePassword"]),
    validatePasswordRule(value) {
      if (!value) return "Required";
      if (value && value === this.user.password) return true;
      else return "Passwords do not match";
    },
    Authenticate(authUrl) {
      this.loading = true;
      axios.get(authUrl).then(function (response) {
        if (response !== undefined && response.data !== undefined) {
          window.location.replace(response.data);
        }
      });
    },
    async RegisterNewUser() {
      try {
        this.dialogTerms = false;
        if (this.$refs.formNew.validate()) {
          this.validNew = false;
          this.loading = true;
          this.user.subscription_startdate = new Date()
            .toISOString()
            .slice(0, 10);
          this.user.subscription_frequency = "yearly";
          let hashedPass = await this.sha512Hash(this.user.password);
          const NewUser = new URLSearchParams();
          NewUser.append("first_name", this.user.first_name);
          NewUser.append("last_name", this.user.last_name);
          NewUser.append("mobile_number", this.user.mobile_number);
          NewUser.append("email_id", this.user.email_id);
          NewUser.append("password", hashedPass);
          NewUser.append("authentication_app", this.user.authentication_app);
          NewUser.append(
            "subscription_startdate",
            this.user.subscription_startdate
          );
          NewUser.append(
            "subscription_frequency",
            this.user.subscription_frequency
          );
          NewUser.append(
            "authentication_app_id",
            this.user.authentication_app_id
          );
          NewUser.append("picture", this.user.picture);
          NewUser.append("email_verified", this.user.email_verified);
          NewUser.append("locale", this.user.locale);
          NewUser.append("enable_mfa", this.user.enable_mfa);
          NewUser.append("mfa_key", this.user.mfa_key);
          NewUser.append("user_category", this.subscriptionType);
          let responseData = await axios
            .post("Register", NewUser)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined) {
                return response.data; //response.data.message_type + "! " + response.data.message;
              }
            });
          if (responseData.message_type === "Error") this.alertType = "error";
          else this.alertType = "success";
          this.alert_message = responseData.message;
          this.dialog = false;
          this.dialogAlert = true;
          if (this.user.enable_mfa) {
            this.mfa_uri = responseData.mfa_uri;
          }
          this.validNew = true;
          this.loading = false;
        }
      } catch (error) {
        this.validNew = true;
        this.cardTitleRegistration = error;
        this.loading = false;
      }
    },
    async ShowMFADialog() {
      try {
        this.loading = true;
        const NewUser = new URLSearchParams();
        NewUser.append("email_id", this.user.email_id);
        let response = await axios
          .post("GetQrUri", NewUser)
          .then(function (response) {
            if (response !== undefined && response.data !== undefined) {
              return response.data;
            }
          });
        this.mfa_uri = response.mfa_uri;
        this.user.mfa_key = response.userProfile.mfa_key;
        this.loading = false;
        this.showMfa = true;
      } catch (error) {
        this.cardTitleRegistration = error;
        this.loading = false;
      }
    },
    async onQrCodeSubmit(verificationCode) {
      this.loading = true;
      const code = new URLSearchParams();
      code.append("verification_code", verificationCode);
      code.append("mfa_key", this.user.mfa_key);
      try {
        let response = await axios
          .post("ValidateRegistrationOtp", code)
          .then(function (response) {
            if (response !== undefined) {
              return response.data;
            }
          });
        if (response === true) {
          this.cardTitleRegistration = "OTP Validated. please continue";
          this.showMfa = false;
          this.loading = false;
        } else {
          this.cardTitleRegistration =
            "Error validating OTP. Please try again or enable later";
          this.onCloseQrDialog();
          this.loading = false;
        }
      } catch (error) {
        this.cardTitleRegistration =
          "Error in verifying OTP. please try again or enable later";
        this.onCloseQrDialog();
        this.user.mfa_key = "";
        this.loading = false;
      }
    },
    onCloseQrDialog() {
      this.mfa_uri = "";
      this.showMfa = false;
      this.user.enable_mfa = false;
      this.$router.push("/pricing");
    },
    async GetAuthenticationData(code, state) {
      this.dialog = true;
      this.loading = true;
      let resMessage = await axios
        .get(this.authCallbackUrl, {
          params: {
            code: code,
            state: state,
          },
        })
        .then(function (response) {
          return response.data;
        });
      this.user.email_id = resMessage.email_id;
      this.user.first_name = resMessage.first_name;
      this.user.last_name = resMessage.last_name;
      this.user.authentication_app_id = resMessage.authentication_app_id;
      this.user.authentication_app = resMessage.authentication_app;
      this.user.picture = resMessage.picture;
      this.user.email_verified = resMessage.email_verified;
      this.user.locale = resMessage.locale;
      this.loading = false;
      this.showRegistrationForm = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.showRegistrationForm = false;
      window.open(process.env.VUE_APP_UI_PRICING_URL, "_self");
      //window.location.search = "";
    },
    closeAlert() {
      this.dialogAlert = false;
      this.loading = false;
      this.$nextTick(() => {
        this.alert_message = "";
      });
      window.open(process.env.VUE_APP_UI_LOGIN_URL, "_self");
    },
    UpdatePassword(updatedPassword) {
      this.user.password = updatedPassword;
    },
    SubscriptionEvent(sType) {
      this.subscriptionType = sType;
      this.dialog = true;
    },
  },
};
</script>
<style scoped>
.centerAlign {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.textAlign {
  text-align: center !important;
}
p {
  font-size: 20px;
}
</style>