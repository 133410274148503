<template>
  <v-card :color="color" :elevation="value ? 12 : 0" class="pa-2" outlined tile>
    <v-card flat outlined tile>
      <div class="pa-6 pb-12 mb-8">
        <base-title :title="title" class="text-uppercase" space="1" />
        <base-subtitle :title="subtitle" space="8" />
        <div v-if="title === 'Basic'">
          <div class="d-flex align-center justify-center">
            <base-heading mobile-size="display-2" space="0" tag="h4">
              FREE
            </base-heading>
          </div>
        </div>
        <div v-else>
          <div class="d-flex align-center justify-center">
            <base-heading mobile-size="display-2" space="0" tag="h4">
              <sup><small>₹</small></sup
              >{{ price }}
            </base-heading>
            <div
              class="
                text-left
                ml-4
                text-body-2
                font-weight-bold
                mt-1
                text-uppercase
              "
            >
              PER
              <base-divider align="left" color="primary" space="0" />
              {{ interval }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-8" />
      <div class="pb-12">
        <div class="px-8 mb-12">
          <template v-for="(feature, i) in description">
            <div :key="feature" class="my-3" v-text="feature" />
            <v-divider
              v-if="i + 1 < description.length"
              :key="`divider-${i}`"
            />
          </template>
        </div>
        <div v-if="title === 'Basic'">
          <base-btn
            :outlined="!value"
            width="196"
            @click="SubscriptionEvent(title)"
          >
            Get Started
          </base-btn>
        </div>
        <div v-else>
          <base-btn disabled :outlined="!value" width="196">
            Launching Soon
          </base-btn>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "PriceCard",
  provide: {
    heading: { align: "center" },
  },
  props: {
    description: {
      type: Array,
      default: () => [],
    },
    price: [Number, String],
    subtitle: String,
    title: String,
    interval: {
      type: String,
      required: true,
    },
    value: Boolean,
  },
  methods: {
    SubscriptionEvent(subscriptionType) {
      this.$emit("SubscriptionEvent", subscriptionType);
    },
  },
  computed: {
    color() {
      if (!this.value) return undefined;
      return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
  },
};
</script>